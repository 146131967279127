<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h4>Arancel de Precios</h4>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="/dental-clinic/products">Categorias</router-link>
            </li>
            <li
              class="breadcrumb-item active"
              aria-current="page"
              v-if="$route.query.category"
            >
              {{ $route.query.category }}
            </li>
          </ol>
        </nav>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { GeneralService } from "src/general-module/GeneralService.js";

export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    categorie_id: {},
  },

  data: () => ({
    list: {},
  }),

  computed: {
    ...mapState({
      area_id: (s) => s.config.dental.area_id,
    }),
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    async deleteItem(id) {
      if (window.confirm("Eliminar esta categoria")) {
        await GeneralService.deleteCategorie(id);
        this.$refs.tr.gl();
      }
    },
    getList(params) {
      return new Promise(() => {
        GeneralService.getCategories({
          ...params,
          count_products: 1,
          per_page: 999,
        }).then((list) => {
          this.list = list;
        });
      });
    },
  },
};
</script>

<style></style>
